<template>
  <div>
    <v-form>
      <h2 class="mb-4">Gravimetric Analysis of a Metal Carbonate - Data Sheet</h2>

      <p class="mb-2">a) Please enter your experimental data in the table below:</p>

      <v-simple-table class="mb-6">
        <thead>
          <tr>
            <td style="text-align: left; font-weight: bold" class="pl-2">Property</td>
            <td style="text-align: center; font-weight: bold">Value</td>
          </tr>
          <tr v-for="option in options1" :key="option.input">
            <td class="my-1 py-0">
              <stemble-latex :content="option.text" />
            </td>
            <td class="centered-input my-1 py-0">
              <calculation-input v-model="inputs[option.input]" dense :disabled="!allowEditing" />
            </td>
          </tr>
        </thead>
      </v-simple-table>

      <p class="mb-2">
        b) Please calculate the following properties based on the experimental data you provided
        above:
      </p>

      <v-simple-table class="mb-6">
        <thead>
          <tr>
            <td style="text-align: left; font-weight: bold" class="pl-2">Property</td>
            <td style="text-align: center; font-weight: bold">Value</td>
          </tr>
          <tr v-for="option in options2" :key="option.input">
            <td class="my-1 py-0">
              <stemble-latex :content="option.text" />
            </td>
            <td class="centered-input my-1 py-0">
              <calculation-input v-model="inputs[option.input]" dense :disabled="!allowEditing" />
            </td>
          </tr>
        </thead>
      </v-simple-table>

      <p class="mb-n4">c) What is the identity of the Group 1 metal in your unknown carbonate?</p>

      <v-radio-group v-model="inputs.identityMetal" :disabled="!allowEditing" class="mb-0 pl-6">
        <v-radio
          v-for="option in options3"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import CalculationInput from '../inputs/CalculationInput.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'MetalCarbonate_ReportSheet',
  components: {ChemicalLatex, StembleLatex, CalculationInput},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        massCrucible: null,
        massCrucibleUnknownWetCarbonate: null,
        massCrucibleUnknownDryCarbonate: null,
        massFilterPaperWatchGlass: null,
        massFilterPaperWatchGlassWetPrecipitate: null,
        massFilterPaperWatchGlassDryPrecipitate: null,

        massUnknownCarbonate: null,
        massPrecipitate: null,
        molCaCO3: null,
        mwMetalCarbonate: null,
        mwMetal: null,

        identityMetal: null,
      },
      options1: [
        {
          text: 'i) Mass of clean empty crucible',
          input: 'massCrucible',
        },
        {
          text: 'ii) Mass of crucible + unknown carbonate before heating',
          input: 'massCrucibleUnknownWetCarbonate',
        },
        {
          text: 'iii) Mass of crucible + unknown carbonate after heating',
          input: 'massCrucibleUnknownDryCarbonate',
        },
        {
          text: 'iv) Mass of filter paper + watch glass',
          input: 'massFilterPaperWatchGlass',
        },
        {
          text: 'v) Mass of filter paper + watch glass + precipitate before drying',
          input: 'massFilterPaperWatchGlassWetPrecipitate',
        },
        {
          text: 'vi) Mass of filter paper + watch glass + precipitate after drying',
          input: 'massFilterPaperWatchGlassDryPrecipitate',
        },
      ],
      options2: [
        {text: 'i) Mass of unknown carbonate', input: 'massUnknownCarbonate'},
        {text: 'ii) Mass of $\\ce{CaCO3}$ precipitate', input: 'massPrecipitate'},
        {text: 'iii) Moles of $\\ce{CaCO3}$', input: 'molCaCO3'},
        {text: 'iv) Molar mass of unknown carbonate', input: 'mwMetalCarbonate'},
        {text: 'v) Molar mass of the metal in the carbonate', input: 'mwMetal'},
      ],
      options3: [
        {text: 'Li', value: 'Li'},
        {text: 'Na', value: 'Na'},
        {text: 'K', value: 'K'},
        {text: 'Rb', value: 'Rb'},
        {text: 'Cs', value: 'Cs'},
      ],
    };
  },
};
</script>
